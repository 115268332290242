<template>
  <el-dialog
    title="保证金审核"
    :visible.sync="visible"
    :modal-append-to-body="false"
    :lock-scroll="false"
    width="60%"
    @close="close"
    center
  >
    <div class="content">
      <div class="content-table">
        <table border="1">
          <tr>
            <td class="name">项目编号</td>
            <td class="value">
              {{ examineData.xmbh }}
            </td>
            <td class="name">项目名称</td>
            <td class="value">
              {{ examineData.xmmc }}
            </td>
          </tr>
          <tr>
            <td class="name">保证金金额</td>
            <td class="value">
              {{ examineData.bzj ? examineData.bzj : examineData.jybzj }}
            </td>
            <td class="name">保证金截止时间</td>
            <td class="value">
              {{ examineData.jjjzsj }}
            </td>
          </tr>
          <tr>
            <td class="name">缴费凭证上传时间</td>
            <td class="value">
              {{
                examineData.bzjscsj ? examineData.bzjscsj : examineData.pzscsj
              }}
            </td>
            <td class="name">项目所在区域</td>
            <td class="value">
              {{
                examineData.xmAddress ? examineData.xmAddress : examineData.xmdz
              }}
            </td>
          </tr>
          <tr>
            <td class="name">竞价人</td>
            <td class="value">
              {{
                examineData.srfName ? examineData.srfName : examineData.jjLxr
              }}
            </td>
            <td class="name">竞价人联系方式</td>
            <td class="value">
              {{
                examineData.srfLxfs ? examineData.srfLxfs : examineData.jjLxfs
              }}
            </td>
          </tr>
          <tr>
            <td class="name">出让人</td>
            <td class="value">
              {{
                examineData.crfName ? examineData.crfName : examineData.crLxr
              }}
            </td>
            <td class="name">出让人联系方式</td>
            <td class="value">
              {{
                examineData.crfLxfs ? examineData.crfLxfs : examineData.crLxfs
              }}
            </td>
          </tr>
        </table>
        <div class="img">
          <img :src="examineData.bzjUrl" alt="" />
        </div>
        <div class="sm">
          <span class="label">审核说明</span>
          <!-- 需要做的功能，点击驳回，审核说明不能为空 -->
          <el-input
            type="textarea"
            v-model="form.auditDesc"
            minlength="1"
          ></el-input>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <!-- 当输入框为空的时候禁用驳回按钮 -->
      <el-button v-if="examineData.auditStatus == '1'" @click="examine('0')"
        >驳回</el-button
      >
      <el-button
        type="success"
        v-if="examineData.auditStatus == '1'"
        style="width: 150px"
        @click="examine('1')"
        >通过</el-button
      >
      <el-button
        type="success"
        style="width: 116px"
        :disabled="true"
        v-if="examineData.auditStatus == '3'"
        >审核已通过</el-button
      >
      <el-button
        type="danger"
        style="width: 116px"
        :disabled="true"
        v-if="examineData.auditStatus == '4'"
        >审核已驳回</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { bzjAudit } from "@/api/projectManage";
export default {
  data() {
    return {
      form: {
        auditDesc: "",
      },
      checked: false,
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    examineData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    examineData: function () {
      // console.log(this.examineData)
    },
  },
  methods: {
    async examine(type) {
      // 提交
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (type == 0 && !this.form.auditDesc) {
        this.$message.info("请输入驳回原因");
      } else {
        let param = Object.assign(
          {
            xmbh: this.examineData.xmbh,
            auditStatus: type,
            uid: this.examineData.srfId
              ? this.examineData.srfId
              : this.examineData.jjfUid,
            bzjId: this.examineData.bzjId,
          },
          this.form
        );
        let res = await bzjAudit(param);
        let { status, message } = res;
        if (status == 200) {
          this.$message.success(message);
          this.close();
        } else {
          this.$message.error(message);
        }
      }
    },
    close() {
      // 关闭
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="less" scoped>
.sm {
  display: grid;
  grid-template-columns: 140px 1fr;
}
.rules {
  font-size: 15px;
  border-bottom: 1px solid rgb(92, 91, 91);
}
.agree {
  margin-top: 12px;
}
table {
  width: 100%;
  text-align: center;
  border: 1px solid #c9c9c9;
  border-bottom: none;
  tr {
    min-height: 40px;
    height: 40px;
    width: 100%;
    td {
      border-top: none;
      border-left: 1px solid #c9c9c9;
      border-right: 1px solid #c9c9c9;
      border-bottom: 1px solid #c9c9c9;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .value {
    width: 300px;
    border-right: none;
  }
  .name {
    width: 270px;
    border-right: none;
  }
  .name:first-child {
    border-left: none;
  }
}
.img {
  margin: 10px;
  display: flex;
  justify-content: center;
  > img {
    display: inline-block;
    max-width: 600px;
  }
}
</style>
