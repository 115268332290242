/**
 * 交易项目接口管理
 */
import service from '../request'

const baseUrl = `${window.globalUrl.HOME_API_WEI}/trade-website/cqjyService`;

// 获取产权类别
export const getPropertyType = (params) => {
  return service.get(`${baseUrl}/getPropertyType`, {params})
}

// 获取数据
export const getExchangeProData = (param) => {
  return service.post(`${baseUrl}/getTradeItemInfoByType`, param, {isForm: true})
}

// 获取项目详情
export const getProjectDeatil = (params) => {
  return service.post(`${baseUrl}/getItemDetailInfo`, params, {isForm: true})
}

// 查看图片
export const getImageUrl = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/downLoadImages`,
    params: params,
  })
}

// 获取项目地块信息
export const getLandInfo = (param) => {
  return service.post(`${baseUrl}/getDkxxInfoByXmbh`, param, {isForm: true})
}

//
// 查看图片
export const getXmxxDetail = (params) => {
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API}/trade-website/cqjyService/queryXmxxDetail`,
    params: params,
  })
}

//保存保证金凭证
export const savePzxx = (params) => {
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API}/trade-website/cqjy/savePzxx`,
    params: params,
  })
}

//个人中心--竞价
export const saveBidding = (params) => {
  return service({
    method: 'post',
    url: `${window.globalUrl.HOME_API}/trade-website/cqjy/bidding`,
    params: params,
  })
}
// 收藏资源资产
export const collectionXMXX = (params) => {
  return service({
    method: 'post',
    url: `${window.globalUrl.HOME_API}/trade-website/cqjy/collectionXMXX`,
    params: params,
  })
}

//查看保证金审核结果
export const getBzjCheckResult = (params) => {
  return service(({
    method: 'get',
    url: `${window.globalUrl.HOME_API}/trade-website/cqjy/showBzjCheckResult`,
    params: params,
  }))
}

//查询按钮状态
export const getButtonStatus = (params) => {
  return service(({
    method: 'post',
    url: `${window.globalUrl.HOME_API}//trade-website/cqjy/buttonStatus`,
    params: params,
  }))
}

//生产资料供需查看详情
export const getDetail = (params) => {
  return service(({
    method: 'get',
    url: `${window.globalUrl.HOME_API}/trade-website/order/getDetail`,
    params: params,
  }))
}

//合同文件类路径
export const selectPdfFile = (params) => {
  return service(({
    method: 'get',
    url: `${window.globalUrl.HOME_API}/trade-website/cqjy/selectPdfFile`,
    params: params,
  }))
}
